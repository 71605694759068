<template>
  <div class="campaignOverview">
    <div class="campaignOverview__left" v-if="offer">
      <p>What to do for this deal:</p>
      <p class="green">{{ offer.description }}</p>
      <ul class="campaignOverview__left--info">
        <li>
          Offer type:
          <span>{{ offer.type }}</span>
        </li>
        <li>
          Objective
          <span>Tag us: #happyhopes</span>
          <span>Use our images or videos:</span>
        </li>
        <li>
          Budget:
          <!-- <span>{{offer.budget}}</span> -->
        </li>
        <li>
          Target
          <span>Tag us: #happyhopes</span>
          <span>Use our images or videos:</span>
        </li>
      </ul>
      <div class="campaignOverview__left--image" v-if="offer.image">
        <img
          v-lazy="`${$config.IMG_HOST}/650x450/${offer.image}`"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offer: [Object, Array],
  },
};
</script>

<style lang="scss" scoped>
.campaignOverview {
  display: flex;
  &__left {
    flex: 0 0 655px;
    max-width: 655px;
    @media screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    p {
      font-size: rem(16px);
      margin: 0;
      color: var(--textPrimary);
      padding-left: 10px;
      &.green {
        font-weight: 700;
        color: #23d293;
        margin-bottom: rem(18px);
        margin-top: rem(5px);
        line-height: 1.3;
        @media screen and (max-width: 767px) {
          margin-top: rem(12px);
        }
      }
    }
    &--info {
      list-style: none;
      display: flex;
      margin-top: rem(12px);
      padding-bottom: rem(20px);
      border-bottom: 2px solid #c9ced6;
      padding-left: 10px;
      @media screen and (max-width: 667px) {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        border: 0;
        padding-bottom: rem(0px);
      }
      li {
        font-size: rem(16px);
        font-weight: 600;
        color: var(--textPrimary);
        &:not(:last-child) {
          margin-right: rem(34px);
        }
        @media screen and (max-width: 667px) {
          width: 40%;
          margin-bottom: rem(20px);
          &:not(:last-child) {
            margin-right: rem(16px);
          }
        }
        span {
          display: block;
          color: #253858;
          opacity: 0.5;
          font-size: rem(14px);
          font-weight: 400;
          margin-top: 6px;
        }
      }
    }
    &--image {
      margin-top: rem(18px);
      border-radius: 32px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
